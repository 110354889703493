import React, { useState, useEffect } from 'react';
import { Button, Form, ListGroup, Spinner, Modal, Image } from 'react-bootstrap';
import { X, Upload } from 'react-bootstrap-icons';
import './api-image.css';

import { API_BASE_URL } from '../config';

function ApiImage({ headers, onLibrarySelect, selectedLibrary }) {
    const [libraries, setLibraries] = useState([]);
    const [isLoadingLibraries, setIsLoadingLibraries] = useState(true);
    const [images, setImages] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [newLibraryName, setNewLibraryName] = useState('');
    const [showNewLibraryModal, setShowNewLibraryModal] = useState(false);

    useEffect(() => {
        fetchLibraries();
    }, []);

    useEffect(() => {
        if (selectedLibrary) {
            fetchImages();
        }
    }, [selectedLibrary]);

    const fetchLibraries = async () => {
        try {
            setIsLoadingLibraries(true);
            const response = await fetch(`${API_BASE_URL}/imglib`, { headers });
            const data = await response.json();
            setLibraries(data.libraries || []);
        } catch (err) {
            console.error('Failed to fetch libraries:', err);
        } finally {
            setIsLoadingLibraries(false);
        }
    };

    const fetchImages = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/images/${selectedLibrary}`, { headers });
            const data = await response.json();
            setImages(data.images || []);
        } catch (err) {
            console.error('Failed to fetch images:', err);
        }
    };

    const handleUploadImage = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        setIsUploading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/images/upload/${selectedLibrary}`, {
                method: 'POST',
                headers: { ...headers },
                body: formData
            });
            
            if (!response.ok) throw new Error('Upload failed');
            
            await fetchImages();
        } catch (err) {
            console.error('Failed to upload image:', err);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDeleteImage = async (imageId) => {
        try {
            await fetch(`${API_BASE_URL}/images/${selectedLibrary}/${imageId}`, {
                method: 'DELETE',
                headers
            });
            await fetchImages();
        } catch (err) {
            console.error('Failed to delete image:', err);
        }
    };

    const handleLibrarySelect = (library) => {
        if (library === 'new') {
            setShowNewLibraryModal(true);
            return;
        }
        onLibrarySelect(library);
    };

    const handleAddLibrary = async () => {
        if (newLibraryName) {
            try {
                const response = await fetch(`${API_BASE_URL}/imglib`, {
                    method: 'POST',
                    headers: { ...headers, 'Content-Type': 'application/json' },
                    body: JSON.stringify({ name: newLibraryName })
                });
                if (!response.ok) throw new Error('Failed to create library');
                const newLibrary = await response.json();
                await fetchLibraries();
                setNewLibraryName('');
                setShowNewLibraryModal(false);
                onLibrarySelect(newLibrary.id);
            } catch (err) {
                console.error('Failed to create library:', err);
            }
        }
    };

    const handleDeleteLibrary = async (libraryId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/imglib/${libraryId}`, {
                method: 'DELETE',
                headers
            });
            if (!response.ok) throw new Error('Failed to delete library');
            await fetchLibraries();
        } catch (err) {
            console.error('Failed to delete library:', err);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        if (files.length === 0 || !selectedLibrary) return;

        setIsUploading(true);
        try {
            for (const file of files) {
                const formData = new FormData();
                formData.append('file', file);
                await fetch(`${API_BASE_URL}/images/upload/${selectedLibrary}`, {
                    method: 'POST',
                    headers: { ...headers },
                    body: formData
                });
            }
            await fetchImages();
        } catch (err) {
            console.error('Failed to upload images:', err);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="image-library-manager">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center flex-grow-1 me-2">
                    <Form.Select 
                        value={selectedLibrary}
                        onChange={(e) => handleLibrarySelect(e.target.value)}
                        className="me-2"
                        disabled={isLoadingLibraries}
                    >
                        <option value="none">Do not use an imagelibrary</option>
                        <option value="new">Create a new library</option>
                        {libraries.map(lib => (
                            <option key={lib.id} value={lib.id}>{lib.name}</option>
                        ))}
                    </Form.Select>
                    {selectedLibrary !== 'none' && selectedLibrary !== 'new' && (
                        <Button 
                            variant="link" 
                            className="p-0 text-danger"
                            onClick={() => {
                                if (window.confirm('Are you sure you want to delete this library?')) {
                                    handleDeleteLibrary(selectedLibrary);
                                    onLibrarySelect('none');
                                }
                            }}
                        >
                            <X size={20} />
                        </Button>
                    )}
                </div>
                
                
            </div>

            <div 
                className="thumbnail-grid-container"
            >
                <div 
                    className="thumbnail-grid"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    {isUploading && (
                        <div className="upload-overlay">
                            <Spinner animation="border" />
                            <p>Uploading...</p>
                        </div>
                    )}
                    {images.map(image => (
                        <div key={image.id} className="thumbnail-item">
                            <div 
                                className="thumbnail-wrapper"
                            >
                                <div className="thumbnail-preview" 
                                    style={{
                                        backgroundImage: `url(data:image/jpeg;base64,${image.thumbnail})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                />
                            </div>
                            <div className="thumbnail-footer">
                                <span className="filename">{image.filename}</span>
                                <Button 
                                    variant="link" 
                                    className="delete-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteImage(image.id);
                                    }}
                                >
                                    <X />
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal 
                show={showImageModal} 
                onHide={() => setShowImageModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{selectedImage?.filename}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={selectedImage?.url} fluid />
                </Modal.Body>
            </Modal>

            <Modal show={showNewLibraryModal} onHide={() => setShowNewLibraryModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Library</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Library Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={newLibraryName}
                            onChange={(e) => setNewLibraryName(e.target.value)}
                            placeholder="Enter library name"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowNewLibraryModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAddLibrary}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ApiImage;