import React, { useState, useEffect } from 'react';
import { Form, Button, ListGroup, Alert, Spinner, Collapse } from 'react-bootstrap';
import { X, Download } from 'react-bootstrap-icons';
import ApiImage from './api-image';
import { API_BASE_URL } from '../config';

function ApiSlidePatch({ apiKey, apiSecret }) {
    const [patcheRequests, setPatcheRequests] = useState([]);
    const [selectedImageLibrary, setSelectedImageLibrary] = useState('default');
    const [patchCommand, setPatchCommand] = useState('');
    const [isPatching, setIsPatching] = useState(false);
    const [error, setError] = useState('');
    const [useOnlineSearch, setUseOnlineSearch] = useState(false);
    const fileInputRef = React.useRef(null);
    const [expandedPatchId, setExpandedPatchId] = useState(null);
    const headers = {
        'X-API-Key': apiKey,
        'X-API-Secret': apiSecret
    };
    useEffect(() => {
        fetchPatches();
    }, []);

    const fetchPatches = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/patch-slides`, { headers });
            const data = await response.json();
            console.log('Fetched patches:', data);
            setPatcheRequests(data || []);
        } catch (err) {
            console.error('Fetch error:', err);
            setError('Failed to fetch patches');
        }
    };

    const handlePatchSlide = async (e) => {
        e.preventDefault();
        if (!fileInputRef.current?.files?.[0]) {
            setError('Please select a file to patch');
            return;
        }

        setIsPatching(true);
        setError('');

        const formData = new FormData();
        formData.append('file', fileInputRef.current.files[0]);
        formData.append('patch_command', patchCommand);
        formData.append('image_library_id', selectedImageLibrary);
        formData.append('use_online_search', useOnlineSearch);

        try {
            const response = await fetch(`${API_BASE_URL}/patch-slides`, {
                method: 'POST',
                headers: headers,
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to patch slide');
            }

            const responseData = await response.json();
            setPatcheRequests(prevRequests => [responseData, ...prevRequests]);
            setExpandedPatchId(responseData.id);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsPatching(false);
        }
    };

    const handleDeletePatch = async (id) => {
        try {
            await fetch(`${API_BASE_URL}/patch-slides/${id}`, {
                method: 'DELETE',
                headers
            });
            await fetchPatches();
        } catch (err) {
            setError('Failed to delete patch');
        }
    };

    return (
        <div className="slide-patch">
            <h2 className="text-center">Slide Patch</h2>

            <div className="image-library-section mb-4">
                <h4>Image Library</h4>
                <ApiImage id="img-patch"
                    headers={headers}
                    onLibrarySelect={setSelectedImageLibrary}
                    selectedLibrary={selectedImageLibrary}
                />
            </div>

            <Form onSubmit={handlePatchSlide} className="mb-4">
                <Form.Group className="mb-3">
                    <Form.Label>PowerPoint File</Form.Label>
                    <Form.Control
                        type="file"
                        ref={fileInputRef}
                        accept=".pptx"
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Patch Command</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={patchCommand}
                        onChange={(e) => setPatchCommand(e.target.value)}
                        placeholder="Enter patch instructions"
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Check 
                        type="switch"
                        id="use_online_image"
                        label="Use Online Image"
                        checked={useOnlineSearch}
                        onChange={(e) => setUseOnlineSearch(e.target.checked)}
                    />
                </Form.Group>

                <Button type="submit" disabled={isPatching}>
                    {isPatching ? (
                        <><Spinner size="sm" animation="border" /> Patching...</>
                    ) : (
                        'Patch Slide'
                    )}
                </Button>
            </Form>

            <div className="patches-section">
                <h4>Patch Requests</h4>
                <ListGroup>
                    {Array.isArray(patcheRequests) && patcheRequests.map(patch => (
                        <React.Fragment key={patch.id}>
                            <ListGroup.Item 
                                className="d-flex justify-content-between align-items-center"
                                onClick={() => {
                                    if (patch.onedrive?.embed_url && patch.onedrive_patched?.embed_url) {
                                        setExpandedPatchId(expandedPatchId === patch.id ? null : patch.id);
                                        setPatchCommand(patch.patch_prompt || '');
                                        setSelectedImageLibrary(patch.image_library_id || 'default');
                                        setUseOnlineSearch(Boolean(patch.use_online_search==='true'));
                                    }
                                }}
                                style={{ cursor: patch.onedrive?.embed_url && patch.onedrive_patched?.embed_url ? 'pointer' : 'default' }}
                            >
                                <div>
                                    <div>{patch.original_filename}</div>
                                    <small className="text-muted">
                                        {new Date(patch.timestamp).toLocaleString()}
                                    </small>
                                </div>
                                <div>
                                    {patch.new_url && (
                                        <Button 
                                            variant="link"
                                            href={patch.new_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="me-2"
                                            title="Download Patched File"
                                        >
                                            <Download size={16} />
                                        </Button>
                                    )}
                                    {patch.onedrive_patched?.edit_url && (
                                        <Button 
                                            variant="link"
                                            href={patch.onedrive_patched.edit_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="me-2"
                                            title="Edit in OneDrive"
                                        >
                                            <i className="bi bi-microsoft"></i>
                                        </Button>
                                    )}
                                    <Button 
                                        variant="link"
                                        className="text-danger"
                                        onClick={() => handleDeletePatch(patch.id)}
                                    >
                                        <X size={16} />
                                    </Button>
                                </div>
                            </ListGroup.Item>
                            <Collapse in={expandedPatchId === patch.id}>
                                <div className="iframe-container p-3 bg-light">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <h5>Original Presentation</h5>
                                            <iframe
                                                src={patch.onedrive?.embed_url}
                                                width="100%"
                                                height="400"
                                                frameBorder="0"
                                                title="Original Presentation"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <h5>Patched Presentation</h5>
                                            <iframe
                                                src={patch.onedrive_patched?.embed_url}
                                                width="100%"
                                                height="400"
                                                frameBorder="0"
                                                title="Patched Presentation"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </ListGroup>
            </div>

            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </div>
    );
}

export default ApiSlidePatch;
