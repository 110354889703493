import './App.css';
import { Container, Nav, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import ApiDemo from './components/api-demo';
import ApiDemoTranslation from './components/api-demo-translation';
import ApiSlidePatch from './components/api-slide-patch';
import { API_BASE_URL } from './config';

function App() {
  const [activePage, setActivePage] = useState('slides');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [inputApiKey, setInputApiKey] = useState('');
  const [inputApiSecret, setInputApiSecret] = useState('');
  const [error, setError] = useState('');

  // Move API validation logic here
  useEffect(() => {
    const storedApiKey = localStorage.getItem('apiKey');
    const storedApiSecret = localStorage.getItem('apiSecret');
    if (storedApiKey && storedApiSecret) {
      setApiKey(storedApiKey);
      setApiSecret(storedApiSecret);
      validateCredentials(storedApiKey, storedApiSecret);
    }
  }, []);

  const validateCredentials = async (key, secret) => {
    try {
      const response = await fetch(`${API_BASE_URL}/get-templates`, {
        headers: {
          'X-API-Key': key,
          'X-API-Secret': secret
        }
      });
      if (response.ok) {
        localStorage.setItem('apiKey', key);
        localStorage.setItem('apiSecret', secret);
        setApiKey(key);
        setApiSecret(secret);
        setError('');
      } else {
        setError('Invalid credentials');
      }
    } catch (err) {
      setError('Failed to validate credentials');
    }
  };

  if (!apiKey || !apiSecret) {
    return (
      <Container>
        <div className="credentials-form">
          <h2 className="text-center mb-4">Slides API Demo</h2>
          <p className="text-center text-muted mb-4">Please contact TeachingAssist for API key and secret</p>
          <Form>
            <FloatingLabel controlId="apiKeyInput" label="API Key" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter API Key"
                value={inputApiKey}
                onChange={(e) => setInputApiKey(e.target.value)}
              />
            </FloatingLabel>
            <FloatingLabel controlId="apiSecretInput" label="API Secret" className="mb-4">
              <Form.Control
                type="password"
                placeholder="Enter API Secret"
                value={inputApiSecret}
                onChange={(e) => setInputApiSecret(e.target.value)}
              />
            </FloatingLabel>
            <div className="d-grid">
              <Button onClick={() => validateCredentials(inputApiKey, inputApiSecret)} size="lg">
                Validate
              </Button>
            </div>
          </Form>
          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Nav 
        variant="tabs" 
        className="mb-4"
        activeKey={activePage}
        onSelect={(key) => setActivePage(key)}
      >
        <Nav.Item>
          <Nav.Link eventKey="slides">Slides Generation</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="translation">PowerPoint Translation</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="patch">Patch Slides</Nav.Link>
        </Nav.Item>
      </Nav>

      {activePage === 'slides' ? (
        <ApiDemo apiKey={apiKey} apiSecret={apiSecret} />
      ) : (
        activePage === 'translation' ? (
            <ApiDemoTranslation apiKey={apiKey} apiSecret={apiSecret} />
        ) : (
          activePage === 'patch' ?(
          <ApiSlidePatch apiKey={apiKey} apiSecret={apiSecret} />
          ) : null
        )
      )}
      
    </Container>
  );
}

export default App;
