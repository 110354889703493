import React, { useState, useEffect } from 'react';
import { Form, Button, ListGroup, Alert, Spinner } from 'react-bootstrap';
import { X, Download, Pencil } from 'react-bootstrap-icons';

import { API_BASE_URL } from '../config';


function ApiDemoTranslation({ apiKey, apiSecret }) {
    const [translations, setTranslations] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [deletingId, setDeletingId] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedTranslation, setSelectedTranslation] = useState(null);


    const headers = {
        'X-API-Key': apiKey,
        'X-API-Secret': apiSecret
    };

    // Available languages - expand as needed
    const languages = [
        { code: 'English', name: 'English' },
        { code: 'Spanish', name: 'Spanish' },
        { code: 'French', name: 'French' },
        { code: 'German', name: 'German' },
        { code: 'Chinese', name: 'Chinese' },
    ];

    useEffect(() => {
        fetchTranslations();
    }, []);

    const fetchTranslations = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/translate`, { headers });
            const data = await response.json();
            const processedTranslations = data.map(item => ({
                request_id: item.request_id,
                filename: item.original_file,
                status: item.status,
                created_at: item.created_at,
                url: item.status === 'done' ? item.translated_url : item.original_url,
                target_language: item.target_language,
                embedUrl: item.onedrive?.embed_url,
                editUrl: item.onedrive?.edit_url,
            }));
            setTranslations(processedTranslations);
        } catch (err) {
            setError('Failed to fetch translations');
        }
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleTranslateSubmit = async () => {
        if (!selectedFile || !targetLanguage) return;

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('target_language', targetLanguage);

        setIsUploading(true);
        setError('');

        try {
            const response = await fetch(`${API_BASE_URL}/translate/upload`, {
                method: 'POST',
                headers: headers,
                body: formData
            });
            
            if (!response.ok) throw new Error('Upload failed');
            
            const data = await response.json();
            const newTranslation = {
                request_id: data.request_id,
                filename: data.original_file,
                status: data.status,
                created_at: data.created_at,
                url: data.status === 'done' ? data.translated_url : data.original_url,
                target_language: data.target_language,
                embedUrl: data.onedrive?.embed_url,
                editUrl: data.onedrive?.edit_url,
            };
            
            setTranslations(prev => [newTranslation, ...prev]);
            setSelectedTranslation(data.request_id);
            
            setSelectedFile(null);
            const fileInput = document.querySelector('input[type="file"]');
            if (fileInput) fileInput.value = '';
        } catch (err) {
            setError('Failed to upload file for translation');
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = async (requestId) => {
        setDeletingId(requestId);
        try {
            await fetch(`${API_BASE_URL}/translate/${requestId}`, {
                method: 'DELETE',
                headers: headers
            });
            await fetchTranslations();
        } catch (err) {
            setError('Failed to delete translation');
        } finally {
            setDeletingId(null);
        }
    };

    return (
        <div className="translation-demo">
            <h2 className="text-center mb-4">PowerPoint Translation</h2>

            <Form className="mb-4">
                <Form.Group className="mb-3">
                    <Form.Label>Target Language</Form.Label>
                    <Form.Select 
                        value={targetLanguage}
                        onChange={(e) => setTargetLanguage(e.target.value)}
                    >
                        <option value="">Select Language</option>
                        {languages.map(lang => (
                            <option key={lang.code} value={lang.code}>
                                {lang.name}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Upload PowerPoint</Form.Label>
                    <Form.Control
                        type="file"
                        accept=".pptx"
                        onChange={handleFileSelect}
                        disabled={isUploading}
                    />
                </Form.Group>

                <Button 
                    className="mt-3 mb-4"
                    onClick={handleTranslateSubmit}
                    disabled={isUploading || !selectedFile || !targetLanguage}
                >
                    {isUploading ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                            />
                            Translating...
                        </>
                    ) : (
                        'Translate'
                    )}
                </Button>
            </Form>

            <div className="translations-list">
                <h4>Translations</h4>
                <ListGroup>
                    {translations.map(translation => (
                        <React.Fragment key={translation.request_id}>
                            <ListGroup.Item 
                                action
                                active={selectedTranslation === translation.request_id}
                                onClick={() => translation.embedUrl ? 
                                    setSelectedTranslation(selectedTranslation === translation.request_id ? null : translation.request_id)
                                    : null}
                                className="d-flex justify-content-between align-items-center"
                                style={{ cursor: translation.embedUrl ? 'pointer' : 'default' }}
                            >
                                <div>
                                    <div>{translation.filename}</div>
                                    <small className={`${selectedTranslation === translation.request_id ? 'text-light' : 'text-muted'}`}>
                                        {translation.status} - {new Date(translation.created_at).toLocaleString()}
                                        {translation.target_language && ` - ${translation.target_language}`}
                                    </small>
                                </div>
                                <div>
                                    {translation.status === 'done' && (
                                        <a href={translation.editUrl || translation.url} target="_blank" rel="noopener noreferrer">
                                            <Button variant="link" size="sm" className="mx-2">
                                                {translation.editUrl ? (
                                                    <Pencil size={16} />
                                                ) : (
                                                    <Download size={16} />
                                                )}
                                            </Button>
                                        </a>
                                    )}
                                    <Button 
                                        variant="link"
                                        size="sm"
                                        className="text-danger"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDelete(translation.request_id);
                                        }}
                                        disabled={deletingId === translation.request_id}
                                    >
                                        {deletingId === translation.request_id ? (
                                            <Spinner size="sm" animation="border" />
                                        ) : (
                                            <X size={16} />
                                        )}
                                    </Button>
                                </div>
                            </ListGroup.Item>
                            {selectedTranslation === translation.request_id && translation.embedUrl && (
                                <ListGroup.Item className="p-0">
                                    <iframe
                                        src={translation.embedUrl}
                                        width="100%"
                                        height="400"
                                        frameBorder="0"
                                        title="OneDrive Preview"
                                        allowFullScreen
                                    />
                                </ListGroup.Item>
                            )}
                        </React.Fragment>
                    ))}
                </ListGroup>
            </div>

            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </div>
    );
}

export default ApiDemoTranslation;
