import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown, ListGroup, Alert, Spinner, FloatingLabel } from 'react-bootstrap';
import { X, Download, Pencil, Clipboard } from 'react-bootstrap-icons';  // Add this import
import './api-demo.css';
import ReactQuill from 'react-quill';  // Add this import at the top
import 'react-quill/dist/quill.snow.css';  // Add this import at the top
import ApiImage from './api-image';

import { API_BASE_URL } from '../config';

function ApiDemo({ apiKey, apiSecret }) {
    const [templates, setTemplates] = useState([]);
    const [slides, setSlides] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [prompt, setPrompt] = useState('');
    const [error, setError] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [isDeletingTemplate, setIsDeletingTemplate] = useState(false);
    const [isCreatingSlide, setIsCreatingSlide] = useState(false);
    const [deletingSlideId, setDeletingSlideId] = useState(null); // New state for tracking the deleting slide ID
    const [useOnlineSearch, setUseOnlineSearch] = useState(false);
    const [expandedSlideId, setExpandedSlideId] = useState(null);
    const [copiedId, setCopiedId] = useState(null);
    const [selectedImageLibrary, setSelectedImageLibrary] = useState('default');

    const headers = {
        'X-API-Key': apiKey,
        'X-API-Secret': apiSecret
    };

    useEffect(() => {
        fetchTemplates();
        fetchSlides();
    }, [apiKey, apiSecret]);

    const fetchTemplates = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/get-templates`, { headers });
            const data = await response.json();
            // Transform the data structure to match what the component expects
            const transformedTemplates = data.templates.map(template => ({
                id: template.template_id,
                name: template.filename
            }));
            setTemplates(transformedTemplates);
            return {ok: true};
        } catch (err) {
            setError('Failed to fetch templates');
            setTemplates([]); // Reset to empty array on error
            return {ok: false};
        }
    };

    const fetchSlides = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/get-slides`, { headers });
            const data = await response.json();
            const transformedSlides = data.slides.map(slide => ({
                id: slide.request_id,
                name: `${new Date(slide.created_at).toLocaleString()}`,
                url: slide.public_url,
                embedUrl: slide.onedrive?.embed_url,
                editUrl: slide.onedrive?.edit_url,
                prompt: slide.prompt,
                template_id: slide.template_id,
                image_library_id: slide.image_library_id
            }));
            setSlides(transformedSlides);
        } catch (err) {
            setError('Failed to fetch slides');
            setSlides([]);
        }
    };

    const handleUploadTemplate = async (event) => {
        const file = event.target.files[0];
        if (!file) return; // Early return if no file selected
        
        const formData = new FormData();
        formData.append('file', file);

        setIsUploading(true);
        setError(''); // Clear any previous errors
        
        try {
            const response = await fetch(`${API_BASE_URL}/upload-template`, {
                method: 'POST',
                headers: headers,
                body: formData
            });
            
            if (!response.ok) {
                throw new Error(`Upload failed with status: ${response.status}`);
            }
            
            const data = await response.json();
            setSelectedTemplate(data.template_id);
            await fetchTemplates();
        } catch (err) {
            setError('Failed to upload template: ' + err.message);
            // Reset the file input
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } finally {
            setIsUploading(false);
        }
    };

    const handleDeleteTemplate = async (templateId) => {
        setIsDeletingTemplate(true);
        try {
            const response = await fetch(`${API_BASE_URL}/delete-template/${templateId}`, {
                method: 'DELETE',
                headers: headers
            });
            const data = await response.json();
            
            if (response.ok && data.template_id === templateId) {
                // Clear selected template if we're deleting the currently selected one
                if (selectedTemplate === templateId) {
                    setSelectedTemplate(null);
                }
                await fetchTemplates();
            } else {
                throw new Error(data.message || 'Failed to delete template');
            }
        } catch (err) {
            setError(err.message || 'Failed to delete template');
        } finally {
            setIsDeletingTemplate(false);
        }
    };

    const handleCreateSlide = async () => {
        setIsCreatingSlide(true);
        try {
            const response = await fetch(`${API_BASE_URL}/create-slide`, {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    template_id: selectedTemplate,
                    prompt: prompt,
                    use_online_search: useOnlineSearch,
                    image_library: selectedImageLibrary
                })
            });
            const data = await response.json();
            
            if (response.ok) {
                setIsCreatingSlide(false);
                setPrompt('');
                // Create the new slide object
                const newSlide = {
                    id: data.request_id,
                    name: `${new Date().toLocaleString()}`,
                    url: data.public_url,
                    embedUrl: data.onedrive?.embed_url || null,
                    editUrl: data.onedrive?.edit_url || null,
                    prompt: prompt,
                    template_id: selectedTemplate,
                    image_library_id: selectedImageLibrary
                };
                // Add the new slide and set it as expanded
                setSlides(prevSlides => [newSlide, ...prevSlides]);
                setExpandedSlideId(data.request_id);
            } else {
                // Handle API error response
                setError(data.error || 'Failed to create slide');
                setIsCreatingSlide(false);
                // Automatically clear the error after 3 seconds
                setTimeout(() => setError(''), 3000);
            }
        } catch (err) {
            setError('Failed to create slide');
            setIsCreatingSlide(false);
            // Automatically clear the error after 3 seconds
            setTimeout(() => setError(''), 3000);
        }
    };

    const handleDeleteSlide = async (slideId) => {
        setDeletingSlideId(slideId); // Set the ID of the slide being deleted
        try {
            await fetch(`${API_BASE_URL}/delete-slide/${slideId}`, {
                method: 'DELETE',
                headers: headers
            });
            fetchSlides();
        } catch (err) {
            setError('Failed to delete slide');
        } finally {
            setDeletingSlideId(null); // Reset the deleting slide ID
        }
    };

    // Add this new ref for the file input
    const fileInputRef = React.useRef(null);

    // Add this new handler
    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    // Add this configuration for Quill
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['table'],
            ['clean']
        ]
    };

    const handleCopyId = (id) => {
        navigator.clipboard.writeText(id);
        setCopiedId(id);
        setTimeout(() => setCopiedId(null), 2000);
    };

    const handleSlideClick = (slide) => {
        if (slide.embedUrl) {
            setExpandedSlideId(expandedSlideId === slide.id ? null : slide.id);
        }
        setPrompt(slide.prompt || '');
        // Ensure the template_id exists in the templates array before setting it
        const templateExists = templates.some(template => template.id === slide.template_id);
        setSelectedTemplate(templateExists ? slide.template_id : null);
        setSelectedImageLibrary(slide.image_library_id || 'default');
    };

    return (
        <div className="api-demo">
            <h2 className="text-center">Slides API Demo</h2>
            <div className="templates-section">
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleUploadTemplate}
                    accept=".pptx"
                    style={{ display: 'none' }}
                    disabled={isUploading}
                />
                <Dropdown>
                    <Dropdown.Toggle disabled={isUploading || isDeletingTemplate}>
                        {isUploading ? (
                            <><Spinner size="sm" animation="border" /> Uploading...</>
                        ) : isDeletingTemplate ? (
                            <><Spinner size="sm" animation="border" /> Deleting...</>
                        ) : (
                            selectedTemplate ? templates.find(t => t.id === selectedTemplate)?.name : 'Select Template'
                        )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={handleUploadClick} disabled={isUploading || isDeletingTemplate}>
                            Upload New Template
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        {templates.map(template => (
                            <Dropdown.Item 
                                key={template.id}
                                onClick={() => setSelectedTemplate(template.id)}
                                disabled={isUploading || isDeletingTemplate}
                            >
                                {template.name}
                                <Button 
                                    variant="link" 
                                    size="sm"
                                    className="p-0 ms-2 text-danger"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteTemplate(template.id);
                                    }}
                                    disabled={isUploading || isDeletingTemplate}
                                >
                                    <X size={16} />
                                </Button>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className="image-library-section mb-4">
                <h4>Image Library</h4>
                <ApiImage id="img-generation"
                    headers={headers}
                    onLibrarySelect={setSelectedImageLibrary}
                    selectedLibrary={selectedImageLibrary}
                />
            </div>

            <div className="create-slide-section">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4>Prompt for new slide</h4>
                    <Form.Check 
                        type="switch"
                        id="use_online_image"
                        label="Use Online Image"
                        checked={useOnlineSearch}
                        onChange={(e) => setUseOnlineSearch(e.target.checked)}
                    />
                </div>
                <ReactQuill
                    theme="snow"
                    value={prompt}
                    onChange={setPrompt}
                    modules={modules}
                    placeholder="Enter your prompt"
                    style={{ height: '200px', marginBottom: '50px' }}
                />
                <Button 
                    onClick={handleCreateSlide}
                    disabled={!selectedTemplate || !prompt || isCreatingSlide}
                >
                    {isCreatingSlide ? (
                        <><Spinner size="sm" animation="border" /> Creating slide (this may take up to 5 minutes)...</>
                    ) : (
                        'Create Slide'
                    )}
                </Button>
            </div>

            <div className="slides-section">
                <h4>Created Slides</h4>
                <ListGroup>
                    {slides.map(slide => (
                        <React.Fragment key={slide.id}>
                            <ListGroup.Item 
                                className={`d-flex justify-content-between align-items-center ${expandedSlideId === slide.id ? 'active' : ''}`}
                                onClick={() => handleSlideClick(slide)}
                                style={{ cursor: 'pointer' }}
                            >
                                {slide.name}
                                <div onClick={e => e.stopPropagation()}>
                                    <Button 
                                        variant="link" 
                                        size="sm" 
                                        className="mx-2"
                                        onClick={() => handleCopyId(slide.id)}
                                        title={`ID: ${slide.id}`}
                                    >
                                        <Clipboard size={16} />
                                        {copiedId === slide.id && (
                                            <span className="position-absolute translate-middle badge rounded-pill bg-success" style={{ fontSize: '0.6rem' }}>
                                                Copied!
                                            </span>
                                        )}
                                    </Button>
                                    {slide.editUrl && (
                                        <a href={slide.editUrl} target="_blank" rel="noopener noreferrer">
                                            <Button variant="link" size="sm" className="mx-2"
                                                title="Edit"
                                            >
                                                <Pencil size={16} />
                                            </Button>
                                        </a>
                                    )}
                                    {!slide.editUrl && (
                                        <a href={slide.url} target="_blank" rel="noopener noreferrer">
                                            <Button variant="link" size="sm" className="mx-2">
                                                <Download size={16} />
                                            </Button>
                                        </a>
                                    )}
                                    <Button 
                                        variant="link"
                                        size="sm"
                                        title="Delete"
                                        className="text-danger"
                                        onClick={() => handleDeleteSlide(slide.id)}
                                        disabled={deletingSlideId === slide.id}
                                    >
                                        {deletingSlideId === slide.id ? (
                                            <Spinner size="sm" animation="border" />
                                        ) : (
                                            <X size={16} />
                                        )}
                                    </Button>
                                </div>
                            </ListGroup.Item>
                            {expandedSlideId === slide.id && slide.embedUrl && (
                                <ListGroup.Item>
                                    <div className="ratio ratio-16x9">
                                        <iframe
                                            src={slide.embedUrl}
                                            title="PowerPoint Presentation"
                                            frameBorder="0"
                                            allowFullScreen
                                        />
                                    </div>
                                </ListGroup.Item>
                            )}
                        </React.Fragment>
                    ))}
                </ListGroup>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
        </div>
    );
}

export default ApiDemo;
